import React, { useState, useEffect } from 'react';

import { animateScroll as scroll } from 'react-scroll';
import { getPromotionVideos } from '../../services/watch.service';
import { getContent } from '../../services/content.service';

import './SinglePageWatch.component.scss';

function SinglePageWatch() {

    const [videoDetails, setVideoDetails] = useState({});
    // const [isFetching, setIsFetching] = useState(false);

    const setVideos = () => {
        if(!Object.keys(videoDetails).length) {
            return;
        }
        const div = document.createElement('div');
        div.classList.add('watch-block');
        div.innerHTML = `
           
            <div class="embed-code">${ videoDetails.embedCode }</div>
        `;
        const containerElement = document.getElementById('watchVideo');
        if(containerElement) {
            if(videoDetails.source === "youtube") {
                containerElement.classList.add('youtube');
            }
            if(videoDetails.source === "instagram") {
                containerElement.classList.add('instagram');
            }
            if(videoDetails.source === "tiktok") {
                containerElement.classList.add('tiktok');
            }
            containerElement.innerHTML = '';
            containerElement.appendChild(div);
            const instaScript = document.createElement("script");
            instaScript.src = '//platform.instagram.com/en_US/embeds.js';
            instaScript.charset = "utf-8";
            instaScript.async = true;
            document.head.appendChild(instaScript);
            const tiktokScript = document.createElement("script");
            tiktokScript.src = 'https://www.tiktok.com/embed.js';
            tiktokScript.charset = "utf-8";
            tiktokScript.async = true;
            document.head.appendChild(tiktokScript);
        }
    }

    const getPromotionVideosList = (pageNumber) => {
        // setIsFetching(true);
        getPromotionVideos(pageNumber)
        .then((res) => {
            setVideoDetails(res.data.data.list[0]);
            console.log(res.data.data)
            // setIsFetching(false);
        }).catch((err) => {
            // setIsFetching(false);
        })
    }

    useEffect(()=>{
        setVideos();
    }, [videoDetails]);

    useEffect(() => {
        getPromotionVideosList(1);
        scroll.scrollToTop({
            duration: 500,
        });
    }, []);


    useEffect(()=>{
        getContent('watch')
        .then((res) => {
            console.log(res.data);
            document.getElementById("watchContent").innerHTML = res.data.data.content;
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    return (
        <>
            <div className="single-watch-wrapper" id="watchWrapper">
                <div className="title-wrapper">
                    <h1 className="title">
                        Watch
                    </h1>
                </div>

                <div className="content-wrapper" id="watchContent">
                </div>

                {
                    Object.keys(videoDetails).length
                    ? (
                        <div className="single-watch-content" id="watchVideo"></div>
                    )
                    : <div className="single-watch-content" id="watchVideo"></div>
                }

            </div>
        </>
    )
}

export default SinglePageWatch;